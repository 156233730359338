
import { defineComponent, reactive, ref } from 'vue';
import { getListMacLibrary, deleteMacLibrary } from '@/api/single/device';
import { dialog } from '@/util/dialog-render';
import { ElMessageBox } from 'element-plus';

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        // 下拉搜索选项
        const searchOptions = [{
            label: WordList.TabelPersonDeviceInHtmlMAC,
            value: 'MAC'
        }];
        const formData = reactive({
            searchKey: 'MAC',
            MAC: '',
            InsId: props.id ? props.id : '',
            row: 10,
            page: 1
        });
        // 列表数据
        const macData = reactive({
            row: [],
            total: 0
        });
        const columns = [{
            prop: 'MAC',
            label: WordList.TabelPersonDeviceInHtmlMAC
        }, {
            prop: 'Installer',
            label: WordList.ProperAllTextInstaller
        }, {
            prop: 'Owner',
            label: WordList.TabelPersonDeviceInHtmlOwner
        }, {
            prop: 'CreateTime',
            label: WordList.TabelMessageBoxCreateTime
        }, {
            prop: 'op',
            label: WordList.CaptureListTanleAction
        }];
        // 获取列表数据
        function getListMacLibraryApi() {
            getListMacLibrary(formData, (res: any) => {
                console.log(res);
                macData.row = res.row;
                macData.total = res.total;
            });
        }
        getListMacLibraryApi();

        // 下载模板
        function downloadMacTemplate() {
            window.open('/download/template/upload_mac.csv');
        }

        // 上传mac--
        // 获取上传组件
        const uploadRef = ref();
        const uploadHeader = {
            'x-auth-token': `${localStorage.getItem('token')}`,
            'x-cloud-version': process.env.VUE_APP_VERSION
        };
        // 上传的文件
        function handleUploadSuccess() {
            formData.page = 1;
            getListMacLibraryApi();
            uploadRef.value?.uploadRef.clearFiles();
        }
        // 添加mac
        function addNewMac() {
            dialog(
                'views/distributor/user/components/mac-add-dialog',
                {
                    props: {
                        id: props.id,
                        onAddMacLibrarySuccess: () => {
                            formData.page = 1;
                            getListMacLibraryApi();
                        }
                    }

                }
            );
        }
        // 删除mac
        let selectMacIds: string[] = [];
        function deleteMac(id?: string) {
            // 单行删除设备
            if (id) {
                selectMacIds = [id];
            }
            ElMessageBox.confirm(
                WordList.ProperAllTextDeleteTip,
                WordList.TabelDeleteTitle,
                {
                    confirmButtonText: WordList.TabelFootSure,
                    cancelButtonText: WordList.TabelFootCancel,
                    type: 'warning'
                }
            ).then(() => {
                console.log('{ ID: selectMacIds.value, InsId: props.id }', { ID: selectMacIds, InsId: props.id });
                deleteMacLibrary({ ID: selectMacIds, InsId: props.id }, () => {
                    formData.page = 1;
                    getListMacLibraryApi();
                });
            });
        }
        /**
         * 勾选设备行，保存已勾选的id号
         */
        function selectDeviceToDelete(selectItems: any) {
            selectMacIds = [];
            console.log(selectItems);
            selectItems.forEach((item: any) => {
                selectMacIds.push(item.ID);
            });
        }
        return {
            props,
            formData,
            macData,
            columns,
            getListMacLibraryApi,
            downloadMacTemplate,
            searchOptions,
            addNewMac,
            uploadRef,
            uploadHeader,
            deleteMac,
            selectDeviceToDelete,
            selectMacIds,
            handleUploadSuccess
        };
    }
});
